import logo from './logo.svg';
import './App.css';
import { ThemeProvider } from '@mui/material/styles';
import Routes from "./Routes";
import Theme from "./Theme";
function App() {
  return (
    <div className="App">
      <ThemeProvider theme={Theme}>
        <Routes />
      </ThemeProvider>
    </div>
  );
}

export default App;
