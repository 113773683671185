import { Box, CssBaseline, Typography } from '@mui/material'
import React from 'react'
import AppBar from '../components/appBar'
import privacyPolicyData from '../data/privacy-policy'
import Container from '@mui/material/Container';
import Footer from '../components/footer';
const privacyPolicy = () => {
    return (
        <Box >
            <CssBaseline />
            <AppBar  isMenuVisible={false}/>
            <Typography variant='h4' sx={{ mt: 4 }}>
                Privacy Policy
            </Typography>
            <Container sx={{ py: 8 }} maxWidth="lg">

                <Box sx={{ textAlign: 'justify' }}>
                    {privacyPolicyData.map(pd => (
                        <Box>
                            <br />
                            <Typography sx={{ fontWeight: 'bold' }} variant='body1'>{pd.header}</Typography>
                            {pd.body.length > 0 && pd.body.map(b => (<Typography variant='body2'>{b}</Typography>))}
                            {pd.subPoints && pd.subPoints.map(sp => (<Box>
                                <br />
                                <Typography sx={{ fontWeight: 'bold' }} variant='body1'>{sp.header}</Typography>
                                <Typography variant='body2'>{sp.body}</Typography>
                            </Box>))}
                            {pd.points && pd.points.map(p => (<Typography variant='body2' sx={{ ml: 4, display: 'flex', flexDirection: 'row' }}  ><li></li> {p}</Typography>))}
                        </Box>
                    ))}
                </Box>
                < Container
                    maxWidth="lg"
                    component="footer"
                    sx={{
                        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
                        mt: 8,
                        py: [3, 6],
                    }
                    }
                >
                    <Footer />

                </Container >
            </Container >

        </Box >
    )
}
export default privacyPolicy