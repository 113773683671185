import React from "react";
import {CssBaseline, Grid} from "@mui/material";

import { makeStyles } from "@mui/material";
import PageNotFound from "../assets/images/page-not-found.png";
import AppBar from '../components/appBar'

export default function SignInSide() {

    return (
        <div>
            <CssBaseline/>
            <AppBar />

            <div style={{ padding: 16 }}>

                <Grid container component="main" >
                    {/* <CssBaseline /> */}
                    <Grid item xs={12} sm={12} md={12} xl={12}  >
                    <img style={{ width: '100vh',height:'80vh' }} 
                         src={require('../assets/images/page-not-found.png')} />
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}
