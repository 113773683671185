// import {
//     Card,
//     CardContent,
//     Typography,
//     IconButton,
// } from "@material-tailwind/react";
import {
    Card,
    CardContent,
    Typography,
    IconButton,
} from '@mui/material';
import FingerPrintIcon from '@mui/icons-material/PhotoCamera';

export function FeatureCard({ color, Icon, title, description }) {
    console.log(Icon);
    return (
        <Card className="mb-2 rounded-2xl shadow-lg shadow-gray-500/10 ">
            <CardContent className=" px-8 text-center">
                <IconButton
                    variant="gradient"
                    size="lg"
                    color={color}
                    className="pointer-events-none mb-6 rounded-full bg-gray-300"
                >
                    <Icon className="fill-blue-700"/>
                </IconButton>
                <Typography variant="h6" className="mb-2" color="blue-gray">
                    {title}
                </Typography>
                <Typography className="font-normal text-blue-gray-500 text-center">
                    {description}
                </Typography>
            </CardContent>
        </Card>
    );
}



export default FeatureCard;
