import { createTheme } from '@mui/material/styles';
import {  green, blue,red } from "@mui/material/colors";

const theme = createTheme({
  palette: {
    primary: {
      main: blue[700],
    },
    secondary: {
      main: red[700],
    },
    success: {
      main: green[700],
    },
  },
});

export default theme;
