export const privacyPolicy = [
    {
        header: "Objective",
        body: ['This Privacy Statement articulates the privacy and data protection principles followed by Peakthink Learning Private Limited (hereinafter referred to as “Peakthink Learning Private Limited”) and its entities around the world with regards to the personal information of its customers.', 'This Privacy Statement explains the use and protection of personal information collected by Peakthink Learning Private Limited. It applies to any personal information you provide to Peakthink Learning Private Limited and, subject to local law, any personal information we collect from other sources.']
    },
    {
        header: "Scope of this Privacy Statement",

        body: ['This Privacy Statement covers our information practices, including how we collect, use, share and protect the personal information you provide to us, through our web sites that link to this Privacy Statement (collectively referred to as “Peakthink Learning Private Limited’s Web sites”).', 'We may also provide social media features on our website that enable you to share Peakthink Learning Private Limited information with your social networks and to interact with Peakthink Learning Private Limited on various social media sites. Your use of these features may result in the collection or sharing of information about you, depending on the feature. We urge all of you to review the privacy policy and other technical settings on the social media sites to make sure you understand how the information provided by you could be used or shared by those sites.', 'Peakthink Learning Private Limited’s websites are not directed at nor targeted at children. By accessing this website, you represent and warrant that you are 13 years of age or older.']
    },
    {
        header: "Personal information collected by Peakthink Learning Private Limited:",
        body: ["We may collect personal information such as your First Name, Last Name, E-mail Address, Job Title, Organization Name, Country, City and Phone Number.  When you provide information that enables us to respond to your request for more details and explanations, we will provide, wherever permissible by relevant laws, collect, use and disclose this information to third parties for the purposes described in this Privacy Statement."]
    },
    {
        header: "Personal information that are collected by Peakthink Learning Private Limited directly through websites or emails:",
        body: ['We may collect personal information such as your First Name, Last Name, E-mail Address, Job Title, Organization Name, Country, City and Phone Number, Industry type when you fill forms on or raise a query through our websites, or when you send emails, or when you make a call to us or when you write to us.']
    },
    {
        header: "Personal information that is collected from third party sources:",
        body: ['Engage with Peakthink Learning Private Limited through social media', 'Our company website is interconnected with social media sites and hence when you engage with such sites, you may indirectly give access to certain information from y our social media profile based upon your privacy preference settings on such said platforms.', 'You can engage with us through social media websites or through features on Peakthink Learning Private Limited websites that integrate with social media sites. When you engage with us through social media sites, you may allow us to have access to certain information from your social media profile based upon your privacy preference settings on such platform.']
    },
    {
        header: "How We Use your Data:",
        body: ['The personal information we collect may be used to:'],
        points: ['provide information and explanations as requested by you.', 'Request for information on our product platforms', ' to enable you to download collaterals and Marketing materials.', 'Providing billing and administration, certain client communication', ' conduct data analysis.', 'assess web site performance and usage analysis', 'maintain sales and marketing leads', 'improve marketing and/or promotional activities', 'create brand awareness', ' generation of demand and optimizing client expectations', ' market products and services based on legitimate business interest under the applicable law; or', 'conduct processing necessary to fulfil other contractual obligations for the individual.']
    },
    {
        header: "Sharing of personal information with the third parties:",
        body: ['Peakthink Learning Private Limited does not share personal information about you with affiliates, partners, service providers, group entities and non-affiliated companies except: (a) to provide products or services you’ve requested; (b) when we have your permission: or (c) under the following circumstances:'],
        points: [" We may share information with affiliated entities/subsidiaries/branch offices for legitimate business purposes.", "We may provide the information to trusted entities who work on behalf of or with Peakthink Learning Private Limited under strict confidentiality agreements.", "Govt and Legal compliance requirement: We may share information with statutory authorities, government institutions, law enforcement agencies or other bodies.", "We may use the information to respond to exercise our legal rights or defend against legal claims.", " We may share information where we believe it is necessary in order to protect or enforce Peakthink Learning Private Limited’s rights, usage terms, intellectual or physical property or for safety of Peakthink Learning Private Limited or associated parties.", "We may share information about you if Peakthink Learning Private Limited is acquired by or merged with another company after ensuring such company offers equivalent level of protection for personal data"],
    },
    {
        header: "Preferences:",
        body: ["Peakthink Learning Private Limited takes reasonable steps to keep your personal information accurate and complete. You can access or update your personal information in the following ways."],
        subPoints: [{ header: "Profile", body: "If your profile was created by you in any of our websites, we allow you to update your contact information when you log into our system." }, { header: "Newsletters", body: "If you are a subscriber to any of newsletters, we enable you to un-subscribe if you so desire.", }]
    },
    {
        header: "Mobile Devices",
        body: ['You could either uninstall using your mobile process or you can manage the same using mobile preference setting.', "Cookies and other technologies", "A cookie is a small file, typically of letters and numbers, downloaded to a user’s computer or mobile device when the user accesses certain websites. Cookies are then sent back to originating website on each subsequent visit. Cookies are useful because they allow a website to recognize a user’s device. They are used for a wide variety of purposes such as website analytics – counting visitors and their behavior, targeted advertising, recording user preferences or for authentication.", "We use cookies on our websites to personalize content and ads, to analyze our traffic and performance, to provide social media features and for the functioning of the website.", "You can choose to accept or decline cookies, either by selecting accept or decline in the banner on your first visit (in the latter case we will try to set a cookie to record your preference for future visits), or by changing the settings in your web browser."]
    },
    {
        header: "Email:",
        body: ["You can contact us at the e-mail or postal address listed in the “Contact Us” section at the bottom of this Statement. Please include your current contact information, the information you are interested in accessing and your requests with regard to the same if any. We will endeavour to respond to your request, subject to reasonable limitations provided by law, unless it infringes on the privacy of other individuals."]
    },
    {
        header: "Data Protection and Confidentiality:",
        body: ["Peakthink Learning Private Limited has implemented reasonable and adequate physical, technical and administrative security measures to protect personal information from loss, misuse, alteration or destruction."]
    },
    {
        header: "Your (“Data Subjects”) rights with respect to your personal information:",
        body: ["Your rights may differ depending on applicable data protection local laws. We respect your right to be informed, access, correct, request deletion or request restriction, portability, objection, and rights in relation to automated decision making and profiling, in our usage of your personal information as may be required under applicable law. We also take steps to ensure that the personal information we collect is accurate and up to date. Subject to such laws, you may have the following rights:"],
        points: ['We all you to know what information we have about you – you can access it by yourself', "On request we can provide your personal information in a readable document", " If the personal information is wrong, you either inform us or you can correct it by yourself"]

    },
    {
        header: "Right to opt out",
        body: ["Peakthink Learning Private Limited respects your privacy considerations and hence provides an option to you, to not provide the data or information sought to be collected."]
    },
    {
        header: "Access and rectification",
        body: ["You have a right to request access to your personal information. You may send us a request for access."]
    },
    {
        header: "Retention of personal information:",
        body: ["Your personal information is retained by us as long as we deem it fit and help us in providing the information for all requests made by you"]
    },
    {
        header: "Authenticity:",
        body: ["Peakthink Learning Private Limited shall not be responsible for the authenticity of the personal information supplied to it by its service providers, whose duty it is to ensure that the information provided by it or its employees is authentic."]
    },
    {
        header: "Grievance Redressal:",
        body: ["Any grievance or complaint, in relation to processing of personal information, should be sent to Peakthink Learning Private Limited in writing to following contact email ID. Grievance shall be redressed as expeditiously as possible. The contact information for the Grievance Officer for India is Sadhruva, Director – Head of Global Data Privacy Office; e-mail address – contact@impruapp.com"]
    },
    {
        header: "Effective Date:",
        body: ["This Privacy Statement is effective from 30th July 2021 and it supersedes all existing polices on the subject matter."]
    },
    {
        header: "Changes to this Privacy Statement:",
        body: ["Please note that this Privacy Statement may be subject to change from time to time. The revised Privacy Statement will accordingly be published on this page.  We will not reduce your rights under this Privacy Statement without your explicit consent. Please regularly check this Privacy Statement to ensure you are aware of the latest updates with respect to the same.", "This Privacy Statement was last updated on 30th July 2021"]
    },
    {
        header: "Contact Us",
        body: ["If you have any questions or comments regarding this Privacy Statement or processing of your personal information, please fill out the form below or contact us by writing to", "Peakthink Learning Private Limited,", "#01 Toyota Road, Ningaihanadoddi, Bidadi, Rmanagar Karnataka-562109"]
    },
    {
        header: "Email: support@impruapp.com",
        body: []
    }
]

export default privacyPolicy;