import { Typography, IconButton } from "@mui/material";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import socialMediaData from '../data/social-media.data'
const title = "ImpruApp ";
const description =
    "A Mobility / Internet based collaborative platform for Student, Teacher, Parent and School"

const year = new Date().getFullYear();
const copyright = (
    <>
        Copyright © {year} ImpruApp{" "}
    </>
)

const links = [{ name: "Privacy Policy", path: "/privacy-policy" }]


export function Footer({ }) {
    return (
        <section className=" bg-gray-50 px-4 pt-4">
            <footer className="relative px-4 pt-8 pb-6">
                <div className="container mx-auto">
                    <div className="flex flex-wrap text-center lg:text-left">
                        <div className="w-full px-4 lg:w-6/12">
                            <Typography variant="h4" className="mb-2" color="blue-gray">
                                {title}
                            </Typography>
                            <Typography className="font-normal text-blue-gray-500">
                                {description}
                            </Typography>
                            {/* <div className="mx-auto mb-8 flex justify-center gap-2 md:mb-0 lg:justify-start">
                                {socialMediaData.map((sm) => (

                                    <IconButton color="white" className="rounded-full" onClick={() => window.open(sm.link, "_blank")}>
                                        <sm.Icon />
                                    </IconButton>

                                ))}
                            </div> */}
                        </div>
                        <div className="mx-auto mt-12 grid w-max grid-cols-1 gap-24 lg:mt-0">
                            {/* add contact details here */}
                            <div >
                                <Typography className="flex items-center text-center justify-center">Contact Us</Typography>
                                <div className="flex flex-row ">
                                    <IconButton aria-label="share" >
                                        <LocalPhoneIcon />
                                    </IconButton>
                                    <Typography className="flex items-center">+91 86608 65953  </Typography>
                                </div>
                                <div className="flex flex-row ">
                                    <IconButton aria-label="share" >
                                        <EmailIcon />
                                    </IconButton>
                                    <Typography className="flex items-center">contact@impruapp.com  </Typography>
                                </div>
                            </div>
                            {/* <div>
                                <Typography className="flex items-center">Other Links  </Typography>
                                {links.map((item) => (
                                    <Typography
                                        as="a"
                                        href={item.path}
                                        target="_blank"
                                        rel="noreferrer"
                                        variant="small"
                                        className="block font-normal text-blue-gray-500 hover:text-blue-gray-700"
                                    >
                                        {item.name}
                                    </Typography>
                                ))}
                            </div> */}
                        </div>
                    </div>
                    <hr className="my-6 border-gray-300" />
                    <div className="flex flex-wrap items-center justify-center md:justify-between">
                        <div className="mx-auto w-full px-4 text-center">
                            <Typography
                                variant="small"
                                className="font-normal text-blue-gray-500"
                            >
                                {copyright}
                            </Typography>
                            {links.map((item) => (
                                <Typography
                                    as="a"
                                    href={item.path}
                                    target="_blank"
                                    rel="noreferrer"
                                    variant="small"
                                    className="block font-normal text-blue-gray-500 hover:text-blue-gray-700"
                                >
                                    {item.name}
                                </Typography>
                            ))}
                        </div>

                    </div>
                </div>
            </footer>
        </section>
    );
}


export default Footer;
