import React from 'react'
import { AppBar, Avatar, Box, Button, IconButton, Link, Menu, MenuItem, Toolbar, Typography } from '@mui/material'
import { grey } from '@mui/material/colors';
import logo from '../assets/images/logo.png'
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from "react-router-dom";
const pages = ['About', 'Features', 'Contact',];
const CustomAppBar = (props) => {
    const { onClickAboutUs,
        onClickFeatures,
        onClickContactUs,
        isMenuVisible
    } = props
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const navigate = useNavigate()
    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = (path) => {
        if (path == 'About') {
            onClickAboutUs()
        }
        if (path == "Features") {
            onClickFeatures()
        }
        if (path == "Contact") {
            onClickContactUs()
        }
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <AppBar
            position="static"
            // color="default" // default is gray
            elevation={0}
            sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
        >
            <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }} >
                <Box sx={{ ml: 14, display: { xs: 'none', md: 'flex' } }} />
                <Avatar sx={{ bgcolor: grey[300], mr: 2, }} src={logo} onClick={() => navigate('/')} >
                    {"Impru".charAt(0).toUpperCase()}
                </Avatar>
                <div className='text-justify'>
                    <Typography variant="h4"
                        component="div"
                        onClick={() => navigate('/')}
                    >
                        ImpruApp
                    </Typography>
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <Typography variant="p" sx={{ size: 'small' }}
                        >
                            A Mobility / Internet based collaborative platform for
                            Student, Teacher, Parent and School
                        </Typography>
                    </Box>
                </div>

                <Box sx={{ flexGrow: 1 }} />
                {isMenuVisible

                    &&
                    <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem key={page} onClick={() => {
                                    handleCloseNavMenu(page)

                                }}>
                                    <Typography textAlign="center">{page}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                }
                {isMenuVisible

                    &&
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        {pages.map((page) => (
                            <Button
                                key={page}
                                onClick={() => {
                                    handleCloseNavMenu(page)

                                }}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                {page}
                            </Button>
                        ))}
                    </Box>}

                {/* </div> */}
                {/* <Button href="#" variant="outlined" sx={{ my: 1, mx: 1.5 }}>
            Login
        </Button> */}
            </Toolbar>
        </AppBar>
    )
}

export default CustomAppBar