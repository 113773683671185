import * as React from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import FeatureCard from '../components/feature-card'
import featuresData from '../data/features-data'
import Footer from '../components/footer'
import AppBar from '../components/appBar'
import Testimonial from '../components/testimonial-card'
import teamwork from '../assets/images/impru-mobile.png'
import testimonialsData from '../data/testmonials-data'


export default function Home() {
    const aboutUs = React.useRef(null)
    const features = React.useRef(null)
    const contactUs = React.useRef(null)

    const scrollToSection = (elementRef) => {
        window.scrollTo({
            top: elementRef.current.offsetTop,
            behavior: "smooth",
        });
    };

    return (
        <div>
            <CssBaseline />
            <AppBar
                onClickAboutUs={() => scrollToSection(aboutUs)}
                onClickFeatures={() => scrollToSection(features)}
                onClickContactUs={() => scrollToSection(contactUs)}
                isMenuVisible={true}
            />

            <main>

                <Box>
                    <img style={{ width: "100%", hight: "400px" }} src={require('../assets/images/students.jpg')} />
                </Box>
                <Container sx={{}} maxWidth="lg" >
                    <Box
                        sx={{
                            bgcolor: 'background.paper',
                            // pt: 8,
                            // pb: 6,
                        }}

                    >

                        <Container className=" bg-gray-50 px-4 pb-20 pt-4">
                            <div className="mt-32 flex flex-wrap items-center">
                                <div className="mx-auto -mt-8 w-full px-4 md:w-5/12">
                                    <Typography
                                        variant="h4"
                                        className="mb-3 font-bold text-center"
                                    >
                                        ImpruApp
                                    </Typography>
                                </div>
                                <div className="mx-auto w-full px-4">
                                    <Typography className="mb-8 font-normal text-blue-gray-500 text-center">
                                        Continuous assessment of each student’s grasp of learning - NEP 2020
                                        <br />
                                        Facilitating effective remedial actions between student and teachers for each lesson - UDISE 2021-22
                                        <br />
                                        Timely feedback to students / parents based on the learning pattern -ASER 2022
                                    </Typography>
                                </div>
                            </div>
                        </Container>
                    </Box>
                    <hr className="border-gray-50" />
                    <section className=" bg-gray-50 px-4 pb-20 pt-4" ref={aboutUs}>
                        <div className="mt-32 flex flex-wrap items-center">
                            <div className="mx-auto -mt-8 w-full px-4 md:w-5/12">
                                <Typography
                                    variant="h4"
                                    className="mb-3 font-bold text-left"
                                    color="blue-gray"
                                >
                                    Who we are
                                </Typography>
                                <Typography className="mb-8 font-normal text-blue-gray-500 text-left text-justify">
                                    For a successful educational journey, for Students, Teachers and Parents, effective systems are constantly explored based on new challenges and technology developments.
                                    ImpruApp brings the perspective of technology and a new approach for all the participants to make learning AnyTime, Anywhere!
                                    It addresses the needs of educational establishments like schools and learning centers.
                                    <br />
                                    <br />
                                    ImpruApp is for students in classes 8, 9, 10, 11 and 12, for the subjects Physics, Chemistry, Biology and Mathematics.
                                    The effectiveness of learning can be assessed at a chapter, sub-units and concept level. The performance of the students can be tracked by all the participants and to the levels mentioned above, to understand the areas of improvement.
                                    This is done through a simple mobile application.
                                </Typography>
                            </div>
                            <div className="mx-auto mt-24 flex w-full justify-center px-4 md:w-4/12 lg:mt-0">
                                <Card className="shadow-lg shadow-gray-500/10">
                                    <CardMedia
                                        component="img"
                                        height="300"
                                        src={teamwork}
                                        alt="impru"
                                    />

                                </Card>
                            </div>
                        </div>
                    </section>
                    <hr className="border-gray-50" />
                    <section className=" bg-gray-50 px-4 pb-20 pt-4" ref={features}>
                        <div className="mt-32 flex flex-wrap items-center">
                            <div className="mx-auto -mt-8 w-full px-4 md:w-12/12">
                                <Typography variant="h4"
                                    className="mb-3 font-bold text-center"
                                    color="blue-gray">What we offer</Typography>
                                <Typography className="mb-8 font-normal text-blue-gray-500 text-left text-justify">
                                    We offer Teacher and Student supportive platform, driven by data, owned by school.
                                    Digital learning environment for students, enabling teachers, parents and schools to track and enable learning outcomes.
                                    Regular assessment  of each student,
                                    Identifying and bridging the learning gaps,
                                    Creating deeper understanding of the concept and
                                    addressing doubts and discussions

                                </Typography>
                            </div>
                        </div>
                        <div className="container mx-auto m-16">
                            <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
                                {featuresData.map(({ color, title, Icon, description }) => (
                                    <FeatureCard
                                        key={title}
                                        color={color}
                                        title={title}
                                        Icon={Icon}
                                        description={description}
                                    />
                                ))}
                            </div>
                        </div>
                    </section>
                    {/* <hr className="border-gray-50" />
                    <section className=" bg-gray-50 px-4 pb-20 pt-4">
                        <div className="mt-32 flex flex-wrap items-center">
                            <div className="mx-auto -mt-8 w-full px-4 md:w-12/12">
                                <Typography variant="h4"
                                    className="mb-3 font-bold text-center"
                                    color="blue-gray">What our clients say?</Typography>
                            </div>
                        </div>
                        <div className="container mx-auto m-16">
                            <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3"></div>
                            <Grid container spacing={4}>
                                {testimonialsData.map(t => (
                                    <Grid item key={t} xs={12} sm={6} md={4}>
                                        <Testimonial testimonial={t} />
                                    </Grid>
                                ))}

                            </Grid>
                        </div>
                    </section> */}
                </Container>
            </main >


            < Container
                ref={contactUs}
                maxWidth="lg"
                component="footer"
                sx={{
                    borderTop: (theme) => `1px solid ${theme.palette.divider}`,
                    mt: 8,
                    py: [3, 6],
                }
                }
            >
                <Footer />

            </Container >
            {/* End footer */}
        </div >
    );
}