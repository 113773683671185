// import {
//     StarIcon,
//     ArrowPathIcon,
//     FingerPrintIcon,
// } from "@mui/icons-material";

import StorageIcon from '@mui/icons-material/Storage';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import AssessmentIcon from '@mui/icons-material/Assessment';
import QuizIcon from '@mui/icons-material/Quiz';
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';
export const featuresData = [
    {
        color: "blue",
        title: "Question Bank",
        Icon: StorageIcon,
        description:
            "Built-in High Quality Syllabus oriented question bank",
    },
    {
        color: "red",
        title: "Test Generator",
        Icon: AssignmentIcon,
        description:
            "Create, Distribute, Customize Formative & summative",
    },

    {
        color: "teal",
        title: "Attend Tests",
        Icon: PhoneAndroidIcon,
        description:
            "Attend tests wherever you are, Instant test results, key answers with detailed explanation",
    },
    {
        color: "teal",
        title: "Doubts and FAQs",
        Icon: QuizIcon,
        description:
            "Ask doubts on any learning concept Teachers clarification",
    },
    {
        color: "teal",
        title: "Performance Reports",
        Icon: AssessmentIcon,
        description:
            "View performance of an individual or class. Detailed reports for Students, Teachers, Parents and Institute",
    },
    {
        color: "teal",
        title: "Communication",
        Icon: MarkUnreadChatAltIcon,
        description:
            "Communication methods for teachers, students and parents",
    },
];

export default featuresData;
