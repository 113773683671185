import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import { Provider } from "react-redux";
// import store from "./redux-store/store";
// import PrivateRoute from './routing/PrivateRoute'
import Home from './pages/home'
import PrivacyPolicy from './pages/privacy-policy'
import PageNotFound from "./pages/pageNotFound";
const Routing = () => {
    return (
        // <Provider store={store}>
        <Router>
            <Routes>



                <Route path="/" exact element={
                    <Home />
                } />
                <Route path="/privacy-policy" exact element={
                    <PrivacyPolicy />
                } />
                <Route exact path="*" element={<PageNotFound />} />

            </Routes>
        </Router>
        // </Provider >
    )
}

export default Routing;