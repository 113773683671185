
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
export const featuresData = [
    {
        title: "facebook",
        Icon: FacebookIcon,
        link:"https://www.facebook.com/impruapp"
        
    },
    {
        title: "linkedin",
        Icon: LinkedInIcon,
        link:"https://www.linkedin.com/company/impruapp"
        
    },
    {
        title: "instagram",
        Icon: InstagramIcon,
        link:"https://www.instagram.com/impruapp/"
        
    },
    {
        title: "youtube",
        Icon: YouTubeIcon,
        link:"https://www.youtube.com/channel/UCIcj05RfhPa3K1n-ssgkKyQ"
        
    },
];

export default featuresData;
